/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import XButton from 'vux/src/components/x-button';
import XInput from 'vux/src/components/x-input';
import XTextarea from 'vux/src/components/x-textarea';
import { Dialog, Toast } from 'vant';
import UploadImage from '@/components/UploadImage/UploadImage';
import atitle from '@/components/Approve/atitle'; // -----审批节点

import FixedNode from '@/components/Approve/FixedNode';
import AssignNode from '@/components/Approve/AssignNode';
import OfficersNode from '@/components/Approve/OfficersNode';
import NoAssiginNode from '@/components/Approve/NoAssiginNode';
import copyOffNode from '@/components/Approve/copyOffNode';
import copyNoAssiginNode from '@/components/Approve/copyNoAssiginNode';
import copyFixedNode from '@/components/Approve/copyFixedNode';
export default {
  name: 'OutSide',
  components: {
    Group: Group,
    XInput: XInput,
    XTextarea: XTextarea,
    XButton: XButton,
    UploadImage: UploadImage,
    atitle: atitle,
    FixedNode: FixedNode,
    AssignNode: AssignNode,
    OfficersNode: OfficersNode,
    NoAssiginNode: NoAssiginNode,
    copyOffNode: copyOffNode,
    copyNoAssiginNode: copyNoAssiginNode,
    copyFixedNode: copyFixedNode
  },
  data: function data() {
    return {
      interval: '',
      imgList: [],
      //图片列表数组
      limitNum: 1,
      //最多上传图片张数
      point: [],
      jing: '',
      wei: '',
      currDate: '',
      fmtDate: '',
      weekcn: '',
      address: '',
      address2: '',
      customerName: '',
      info: '',
      radioValue: '1',
      commonList: this.$params.commonList,
      //  ----以下是lwt的变量--2020-07-01
      approve: [],
      //要提交的审批节点列表
      allUserList: [],
      //获取过来的审批节点
      allUserListReserved: [],
      //获取过来的审批节点 备份
      process: 0,
      //0简单审批 1条件审批
      userSerial: this.$utils.Store.getItem('userSerial'),
      //人员编号
      approveConditionId: '',
      approveSettingId: '',
      cc: {},
      //抄送人
      haveCC: false,
      showLevel: '',
      sbmitDis: true,
      //是否可以点击
      approveStatues: false,
      //控制提交状态isDisabled
      chaosongzhuangtai: false,
      //控制提交状态isDisabled
      setTimeer: null
    };
  },
  methods: {
    /* 2021/11/15 优化增加删除不影响业务*/
    getShenpiRenshu: function getShenpiRenshu(aa) {
      /* 审批人数关联提交状态子组建传值 */
      if (aa > 0) {
        this.approveStatues = true;
      } else if (aa == 0) {
        this.approveStatues = false;
      }
    },

    /* 2021/11/15 优化增加删除不影响业务*/
    gechaosongrenshu: function gechaosongrenshu(aa) {
      /*抄送人数关联提交状态子组建传值 */
      if (aa > 0) {
        this.chaosongzhuangtai = true;
      } else if (aa == 0) {
        this.chaosongzhuangtai = false;
      }
    },
    jumpMapSmall: function jumpMapSmall() {
      this.$router.push({
        name: 'BdMap',
        params: {
          mapcontrol: 20,
          flag: true
        }
      });
    },
    submit: function submit() {
      var _this = this;

      if (this.customerName === '' || this.customerName === null) {
        this.$utils.Tools.loadHide();
        Toast('请输入客户名称');
        return;
      }

      if (this.imgList.length === 0 && this.$params.attence === '1') {
        this.$utils.Tools.loadHide();
        Toast('请上传照片');
        return;
      }

      if (this.ergArray(this.approve)) {
        this.$utils.Tools.loadHide();
        Toast('未选择审批人');
        return;
      } else if (this.haveCC && !this.cc.userSerial[0]) {
        this.$utils.Tools.loadHide();
        Toast('未选择抄送人');
        return;
      }

      this.sbmitDis = false;
      var images = [];

      var _iterator = _createForOfIteratorHelper(this.imgList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var image = _step.value;
          images.push(image.serverId);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var businessEntity = {
        bh: '00304',
        address: this.address,
        customerName: this.customerName,
        fx: this.radioValue,
        jing: this.jing,
        wei: this.wei,
        lx: '0',
        outsideInfo: this.info.replace(/[\r\n]/g, ''),
        sj: this.currDate,
        userSerial: this.userSerial,
        userNo: this.$utils.Store.getItem('userNo'),
        openId: this.$utils.Store.getItem('openId'),
        lname: this.$utils.Store.getItem('userLname'),
        images: images.join(',')
      };
      var params = {
        businessEntity: businessEntity,
        approveCode: 6,
        approveSettingId: this.approveSettingId,
        approveConditionId: this.approveConditionId,
        approveCodeSub: '00304',
        petitioner: this.$utils.Store.getItem('userSerial'),
        cc: [this.cc],
        showLevel: this.showLevel,
        approve: this.approve
      };
      this.$api.Appr.apply(params).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;
        _this.sbmitDis = true;

        if (code == '625' || code == '626') {
          _this.$utils.Tools.loadHide();

          Dialog.alert({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            width: '70%',
            closeOnPopstate: true
          }).then(function () {
            // on close
            _this.$router.go(0);
          });
          return;
        }

        if (+code !== _this.$code.success) {
          Toast(_this.$t('attence.norepeat'));
        } else {
          Toast(_this.$t('attence.submit')); // this.$router.push('/StartWork');

          setTimeout(function () {
            history.go(-1);
          }, 1500);
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    getSysDate: function getSysDate() {
      var _this2 = this;

      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          Toast(msg);
        } else {
          _this2.currDate = data.sysDate;
          _this2.fmtDate = _this2.$utils.DateUtils.formatDate(data.sysDate, 'YYYY-MM-DD HH:mm');
          _this2.weekcn = _this2.$utils.DateUtils.getWeekCnThree(data.sysDate);
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },
    setDtConfig: function setDtConfig() {
      var _this3 = this;

      var localUrl = window.location.href.split('#')[0];
      this.$api.DingTalk.getDtConfig(localUrl).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          Toast(msg);
        } else {
          _this3.$utils.DingTalk.getConfig(data);

          setTimeout(function () {
            _this3.onDtPosition();
          }, 500);
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    onDtPosition: function onDtPosition() {
      var that = this;
      that.$utils.Tools.loadShow('正在定位');
      that.$utils.DingTalk.dtGetLocation('allmap2', 14, false, function (resp) {
        that.$utils.Tools.loadHide();

        if (resp.flag === true) {
          that.wei = resp.latitude;
          that.jing = resp.longitude;
          that.address = resp.address;
          that.address2 = resp.address2;
          that.$utils.Store.setItem('address', resp.address);
          that.$utils.Store.setItem('resp', JSON.stringify(resp));
        } else {
          Toast(that.$t('location.failed'));
          that.address = '';
        }
      });
      that.setTimeer = setTimeout(function () {
        if (!that.$utils.Store.getItem('address') && !that.$utils.Store.getItem('resp')) {
          that.$utils.Tools.loadHide();
          Toast(that.$t('location.failed'));
          that.address = '';
          that.$utils.Tools.toastShow('获取定位失败，请检查手机GPS是否开启');
        }
      }, 10000);
    },
    setConfig: function setConfig() {
      var _this4 = this;

      var localUrl = window.location.href.split('#')[0];
      this.$api.Sys.getConfig(localUrl, this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this4.$code.success) {
          Toast(msg);
        } else {
          _this4.$utils.WeiXin.wxConfig(data);

          setTimeout(function () {
            _this4.onPosition();
          }, 500);
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    onSubmitOs: function onSubmitOs() {
      var _this5 = this;

      this.$utils.Tools.getEnv(function (res) {
        if (res === 0 || res === 1) {
          _this5.onPosition('submit');
        } else {
          _this5.onDtPosition();
        }
      });
    },
    onPosition: function onPosition(submit) {
      var that = this;
      that.$utils.Tools.loadShow();
      that.$utils.WeiXin.wxGetLocation('allmap2', 14, false, function (resp) {
        that.$utils.Tools.loadHide();

        if (resp.flag === true) {
          that.wei = resp.latitude;
          that.jing = resp.longitude;
          that.address = resp.address;
          that.address2 = resp.address2;
          that.$utils.Store.setItem('address', resp.address);
          that.$utils.Store.setItem('resp', JSON.stringify(resp));
          if (submit) that.submit();
        } else {
          Toast(that.$t('location.failed'));
          that.address = '';
        }
      });
      that.setTimeer = setTimeout(function () {
        if (!that.$utils.Store.getItem('address') && !that.$utils.Store.getItem('resp')) {
          that.$utils.Tools.loadHide();
          Toast(that.$t('location.failed'));
          that.address = '';
          that.$utils.Tools.toastShow('获取定位失败，请检查手机GPS是否开启');
        }
      }, 10000);
    },
    // --------------------------

    /** function 遍历数组是否少
     *   wzx
     *  202-0706
     **/
    ergArray: function ergArray(list) {
      if (!list) {
        return true;
      }

      var uindex = list.findIndex(function (item) {
        return !item.userSerial[0];
      });
      return uindex != -1;
    },

    /** function 当前提交审批节点中是否有对应的节点
     *   lwt
     *   2020-07-03
     * */
    inspectObj: function inspectObj(sub) {
      var index = 0;
      var flag = this.approve.some(function (items, i) {
        if (items.level == sub.level) {
          index = i;
          return true;
        }
      });
      return {
        index: index,
        flag: flag
      };
    },

    /** function 获取子集的节点对象
     *   lwt
     *   2020-07-03
     * */
    getSubUserObj: function getSubUserObj(appr) {
      if (appr.cc && appr.cc == 1) {
        this.cc = appr;
        return;
      }

      if (this.approve.length == 0) {
        this.approve.push(appr);
      } else {
        var insUser = this.inspectObj(appr);

        if (insUser.flag) {
          //如果有重复的
          this.approve[insUser.index] = appr;
        } else {
          //没有重复的
          this.approve.push(appr);
        }
      }
    },

    /** function 获取可审批的节点
     *   lwt
     *   2020-07-04
     * */
    getNodeList: function getNodeList() {
      var _this6 = this;

      //  if (this.ergArray(this.approve)) {
      //    Toast('未选择审批人')
      //     return
      // } else if (this.haveCC&&!this.cc.userSerial[0]) {
      //    Toast('未选择抄送人')
      //     return
      // }
      this.$utils.Tools.loadShow();
      this.haveCC = false;
      this.cc = {};
      this.allUserList = [];
      var params = {
        approveCode: '6',
        //外勤
        subCode: '00304',
        startTime: this.kssj,
        //开始时间
        endTime: this.jssj,
        //结束时间
        userSerial: this.userSerial //人员序列

      };
      this.$api.Appr.getApplicants(params).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (code == '602') {
          _this6.$utils.Tools.loadHide();

          Dialog({
            title: '提示',
            message: msg,
            messageAlign: 'left',
            width: '70%',
            closeOnPopstate: true
          });
          return;
        }

        if (+code !== _this6.$code.success) {
          _this6.$utils.Tools.loadHide();

          Toast(msg);
        } else {
          _this6.$utils.Tools.loadHide();

          _this6.showLevel = data.showLevel;

          if (data.showLevel == '1') {
            _this6.allUserList = data.approve;
          } else {
            for (var i = 0; i < data.approve.length; i++) {
              if (data.approve[i].level < data.showLevel) {
                (function () {
                  var userItem = {};
                  var user = [];
                  data.approve[i].user.some(function (sub) {
                    user.push(sub.userSerial);
                  });
                  userItem.level = data.approve[i].level;
                  userItem.way = data.approve[i].levelWay;
                  userItem.levelId = data.approve[i].levelId;
                  userItem.userSerial = user;

                  _this6.approve.push(userItem);
                })();
              } else {
                _this6.allUserList.push(data.approve[i]);
              }
            }
          }

          if (data.ccSet) {
            _this6.haveCC = true;
            _this6.allUserList[_this6.allUserList.length] = data.ccSet; //抄送
          }

          _this6.approveConditionId = data.approveConditionId;
          _this6.approveSettingId = data.approveSettingId;
          _this6.process = data.process;
        }
      }).catch(function () {
        _this6.$utils.Tools.httpError();
      });
    }
  },
  computed: {
    isDisabled: function isDisabled() {
      if (this.sbmitDis && this.radioValue != '' && this.customerName != '' && this.address != '') {
        /**/
        return false;
      } else {
        return true;
      }
    }
  },
  created: function created() {
    var _this7 = this;

    this.$utils.Store.removeItem('address');
    this.$utils.Store.removeItem('resp');
    this.getNodeList();
    this.point = [];
    this.$utils.Tools.loadShow();
    this.userSerial = this.$utils.Store.getItem('userSerial');
    this.jing = this.$utils.Store.getItem('longitude');
    this.wei = this.$utils.Store.getItem('latitude'); // if (this.jing !== null && this.wei !== null) {
    //   this.address = this.$utils.Store.getItem('address')
    //   this.point.push({longitude: this.jing, latitude: this.wei, range: 1000})
    // } else {

    this.$utils.Tools.getEnv(function (res) {
      if (res === 0 || res === 1) {
        _this7.setConfig();
      } else {
        _this7.setDtConfig();
      }
    }); // }

    this.getSysDate();
    this.interval = setInterval(function () {
      _this7.getSysDate();
    }, 60000);
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {
    clearInterval(this.interval);
    clearTimeout(this.setTimeer);
    this.$utils.Tools.loadHide();
  }
};